<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue?: any; // for two-way binding, see: https://vuejs.org/guide/components/v-model.html
    title: string;
    name?: string;
    mandatory?: boolean;
    type?: string;
    error?: string;
    disabled?: boolean;
    borderless?: boolean;
    inlineError?: boolean; // if true, no error messsage appears below field, just the icon in the field
  }>(),
  {
    modelValue: null,
    mandatory: false,
    type: "text",
    error: "",
    value: "",
    name: "", // fixme: remove altogether, or used anywhere?
    disabled: false,
    borderless: false,
    inlineError: true,
  },
);

defineEmits(["update:modelValue", "keydown"]);

const { t } = useI18n();

// const inputValue = ref(props.value);

const borderClasses = computed(() => {
  const classes: string[] = ["relative", "p-2", "my-2", "rounded-md"];
  if (props.borderless) return classes.join(" ");

  classes.push("border");

  if (props.error) {
    classes.push("border-brand-highlight");
  } else {
    classes.push("border-surface00");
  }

  return classes.join(" ");
});

const titleStyle = computed(() => {
  if (props.borderless) return "top: -10px;";

  return "top: -10px; left: 10px; padding-left: 10px; padding-right: 10px;";
});
</script>

<template>
  <div :class="borderClasses" @keydown="$emit('keydown', $event)">
    <div class="absolute bg-white text-xs" :style="titleStyle">
      {{ props.title }} <span v-if="props.mandatory">*</span>
    </div>
    <input
      :value="modelValue"
      :name="props.name"
      class="w-full outline-none border-none bg-white"
      :type="type"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div
      v-if="props.error && props.inlineError"
      class="absolute inset-y-0 right-1 flex items-center"
    >
      <span class="text-sm m-icon-warning_red" :title="props.error" />
    </div>
  </div>
  <div
    v-if="props.error && !props.inlineError"
    class="text-brand-primary text-xs"
  >
    {{ t(props.error) }}
  </div>
</template>

<style scoped></style>
