<script lang="ts" setup>
withDefaults(
  defineProps<{
    inverted?: boolean;
  }>(),
  {
    inverted: false,
  },
);

const emit = defineEmits(["togglePanel"]);
</script>

<template>
  <div
    class="h-full w-[20] flex items-center justify-end cursor-pointer"
    @click="emit('togglePanel')"
  >
    <i
      class="text-[25px]"
      :class="{
        'm-icon-arrow-up': inverted,
        'm-icon-arrow-down': !inverted,
      }"
    ></i>
  </div>
</template>
